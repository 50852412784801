<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">{{ $store.getters['languageMixin/getStringFromLanguage']('Member List') }}</h4>
         <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['members/create'])"
                      :to="'/members/create'"
                      class="btn btn-info btn-wd" wide>
           <!-- Add New -->
           {{ $store.getters['languageMixin/getStringFromLanguage']('Add New') }}
           <span class="btn-label">
                   <i class="fa fa-plus"></i>
               </span>
         </router-link>
        </div>
        <div>
          <general-data-table
            ref="table"
            :key="componentKey"
            :api-url="'members/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">

              <template slot-scope="scope">

                <span v-html="scope.row[column.value]"></span>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              :label="$store.getters['languageMixin/getStringFromLanguage']('Active')">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['members/change-active-status'])"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              fixed="right"
              :label="$store.getters['languageMixin/getStringFromLanguage']('Actions')">
              <template slot-scope="scope">

                <template>
                  <router-link   v-if="$store.getters['auth/checkAccess']('members/get')"
                                 v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Show')"
                                 class="btn-success btn-simple btn-link"
                                 :to="'/members/show/'+scope.row.id">
                    <i class="fa fa-eye"></i>
                  </router-link>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['members/update'])"
                             v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Edit')"
                             class="btn-warning btn-simple btn-link"
                             :to="'/members/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i></router-link>

<!--                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['members/delete'])"-->
<!--                     v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Delete')"-->
<!--                     class="btn-danger btn-simple btn-link"-->
<!--                     @click="openDeleteModal(scope.row.id)">-->
<!--                    <i class="fa fa-times"></i></a>-->
                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="$store.getters['languageMixin/getStringFromLanguage']('Are you sure you want to delete this member?')"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import LSwitch from "../../components/Switch";


export default {
  components: {
    LSwitch,
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    FgSelect,
  },

  data() {

    return {
      tableColumns: [
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Member Name'), value: 'full_name_cms', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Email'), value: 'email', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Last update / Created at'), value: 'creation_dates', minWidth: '120', align: 'center'},

      ],

      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
    }
  },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
      }
      this.axios.post("members/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("Member updated successfully"),
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    async confirmDeleteModal() {
      try {
        let data = {
            'id': this.toDeleteId,
        }
        await this.axios.delete("members/delete", {headers: {}, data});
        this.$refs.table.getData("updateData");
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("Member deleted successfully"),
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
  },
}
</script>

